import React from 'react';
import './../../styles/style.css';
import './../../styles/userModal.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserModal = ({ token, onClose }) => {
    const PUBLIC_IP = process.env.REACT_APP_PUBLIC_IP;
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            // Call the logout API endpoint if needed
            await axios.post(`${PUBLIC_IP}/logout`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // Remove token and redirect to login page
            localStorage.removeItem('token');
            navigate('/login');
            console.log('Logout successful');
        } catch (error) {
            console.error('Error logging out:', error);
            // Fallback to ensure the user is logged out even if API fails
            localStorage.removeItem('token');
            navigate('/login');
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="modal-close-btn" onClick={onClose}>×</button>
                <ul className="modal-options">
                    <li><a href="/profile">View Profile</a></li>
                    <li><a href="/dashboard">Dashboard</a></li>
                    <li><a href="/settings">Settings</a></li>
                    <li><a href="/subscriptions">Manage Subscriptions</a></li>
                    <li><a href="/help">Help</a></li>
                    <li>
                        <button className="logout-button" onClick={handleLogout}>
                            Logout
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default UserModal;
