import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './../styles/header.css'; // Ensure you update your CSS accordingly
import WebsiteLogo from './../assets/svgs/logo-dark-text-white.svg';
import UserModal from './../components/Modals/UserModal'; // Import the UserModal component

const Header = ({ toggleTheme, theme, username, token }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <header className="header">
            <div className="header-container">
                {/* Logo */}
                <Link to="/" className="logo">
                    <img src={WebsiteLogo} alt="Logo" className="header-logo" />
                </Link>

                {/* Spacer to push the remaining items to the right */}
                <div className="spacer"></div>

                {/* Theme Toggle Button */}
                <button onClick={toggleTheme} className="theme-toggle" aria-label="Toggle Theme">
                    <svg
                        className="feather feather-sun"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="5" />
                        <line x1="12" x2="12" y1="1" y2="3" />
                        <line x1="12" x2="12" y1="21" y2="23" />
                        <line x1="4.22" x2="5.64" y1="4.22" y2="5.64" />
                        <line x1="18.36" x2="19.78" y1="18.36" y2="19.78" />
                        <line x1="1" x2="3" y1="12" y2="12" />
                        <line x1="21" x2="23" y1="12" y2="12" />
                        <line x1="4.22" x2="5.64" y1="19.78" y2="18.36" />
                        <line x1="18.36" x2="19.78" y1="5.64" y2="4.22" />
                    </svg>
                </button>

                {/* Inbox Button */}
                <Link to="#" className="inbox-button" aria-label="Inbox">
                    <svg
                        className="feather feather-mail"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                        <polyline points="22,6 12,13 2,6" />
                    </svg>
                </Link>

                {/* Username */}
                <div className="username">{username}</div>

                {/* Account Button */}
                <button
                    onClick={handleModalToggle}
                    className="account-button"
                    aria-label="Account"
                >
                    <svg
                        viewBox="0 0 256 256"
                        xmlns="http://www.w3.org/2000/svg"
                        className="feather feather-user"
                    >
                        <rect fill="none" height="256" width="256" />
                        <circle cx="128" cy="120" r="44" />
                        <path
                            d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm65.8,162.4a81.3,81.3,0,0,0-24.5-23,59.7,59.7,0,0,1-82.6,0,81.3,81.3,0,0,0-24.5,23,88,88,0,1,1,131.6,0Z"
                        />
                    </svg>
                </button>

                {/* User Modal */}
                {isModalOpen && (
                    <UserModal
                        token={token}
                        onClose={handleModalToggle} // Close modal when onClose is called
                    />
                )}
            </div>
        </header>
    );
};

export default Header;
